<template>
    <v-main style="backgronund-color:#BDBDBD">
        <v-container fluid>
            <router-view></router-view>
        </v-container>
    </v-main>
</template>

<script>
    export default {
        name: 'Content'
    }
</script>

<style scoped>
    .content {
        background-color: #f3f3f4;
    }

</style>
