import axios from 'axios'
import { baseApiUrl,AuthApiUrl,MdfeApiUrl,CteEngineApiUrl,CadastroApiUrl} from '../config'

export const http = axios.create({
    baseURL: baseApiUrl
})
export const httpAuth = axios.create({
    baseURL: AuthApiUrl
})
export const httpMdfe = axios.create({
    baseURL: MdfeApiUrl
})
export const httpCteEngine = axios.create({
    baseURL: CteEngineApiUrl
})
export const httpCadastro = axios.create({
    baseURL: CadastroApiUrl
})

