<template>
    <div class="text-xs-center">
        <v-dialog
                v-model="loading"
                persistent
                max-width="200"
        >
            <v-card  style="background-color:#808080">
                <v-card-text style="color:white">
                    {{ mensagem }} <br/>
                     <v-progress-circular
                        indeterminate
                        color="white"
                    ></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'Loading',
        props: {
            loading: { type: Boolean, default: false },
            mensagem: { type: String, default: 'Carregando' }
        }
    }
</script>

<style scoped>

</style>
