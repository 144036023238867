import {httpAuth,http} from "../plugins/http";
 let endpoint = '/'

export default {
    login: (username,password) => {
        return httpAuth.post(endpoint,{},{
          headers:{
            usuario: username,
            senha: password
          }}
         )
    },
    getUser:()=>{
        return http.get(endpoint+'user',{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    }
}
