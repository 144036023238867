<template>
    <v-app-bar id="nav"
            app>
        <v-app-bar-nav-icon @click.stop="$emit('drawer')" style="color:white"
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title class="headline text-uppercase">
        <span class="font-weight-light d-none d-sm-inline">WorkCTE</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'Header',
        computed: {
        },
        methods: {

        }
    }
</script>

<style scoped>
    .rounded-0{
        border-radius: 0;
        color: red;
        font-size: 30px;
    }
    #nav{
       background-color: #757575;color:white;
    }
</style>
